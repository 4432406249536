import React, { useEffect, useState } from "react"
import { Helmet } from "react-helmet"
import LayoutNoStripe from "../components/layoutNoStripe"
import KarinaGrantLogo from "../components/logo"
import WestfieldCopy from "../components/westfieldCopy"
import { GradientWrapper, ImageFullBackground } from "../styledComponents/imageFullBackground"
const WestfieldBackground = ({ data, location }) => {
 
  
  return (
    <>
    <Helmet>
      <meta name={`robots`} content={`noindex, nofollow`} />
    </Helmet>
    <LayoutNoStripe location={location}  isLander>
    <ImageFullBackground >
      <GradientWrapper>
      <div className="logo-wrapper">
        <KarinaGrantLogo />
        <p className="website">www.karinagrant.co.uk</p>
      </div>
      <div className="program-name">
      <p>Quantum-Touch Energy Healing<span>&amp;</span> Permission to Reset™ Anxiety</p></div>
      <WestfieldCopy />
      </GradientWrapper>
    </ImageFullBackground>
    </LayoutNoStripe>
    </>
  )
}

export default WestfieldBackground